<template>
  <div ref="chart" class="charts" :style="{width, height}"></div>
</template>

<script>
import * as echarts from 'echarts'

export default {
  name: 'Charts',
  props: {
    option: {
      type: Object,
    },
    width: String,
    height: String,
  },
  data () {
    return {
      chart: null,
    }
  },
  mounted () {
    const $chart = this.$refs.chart
    const chart = echarts.init($chart)
    chart.setOption(this.option)
    this.chart = chart
  },
}
</script>

<style lang="less" scoped>

</style>
